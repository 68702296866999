import React from 'react'
import { snackActions } from '../../utils/SnackBarUtils';
import PropTypes from 'prop-types';

export const SnackBarMessage = (props) => {
    const { messageDetails } = props;
    //{message : "Calculated values loaded", severity : "info" }
    React.useEffect(() => {
        if (!messageDetails) {
            return undefined;
        }
        snackActions.toast(messageDetails.message, messageDetails.severity);
    }, [messageDetails]);
    return undefined;
};

SnackBarMessage.propTypes = {
    message: PropTypes.string,
    severity: PropTypes.string,
  };