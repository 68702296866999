import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

const alertSubject = new Subject();
const clearAlertSubject = new Subject();
const defaultId = "default-alert";

export const alertService = {
  onAlert,
  onClearAlert,
  clearAlerts,
  success,
  error,
  info,
  warn,
  alert,
  def,
  clear,
  httpError
};

export const AlertType = {
  Success: "success",
  Error: "error",
  Info: "info",
  Warning: "warning",
  Default: "default",
};

// enable subscribing to alerts observable
function onAlert(id = defaultId) {
  return alertSubject.asObservable().pipe(filter(x => x && x.id === id));
}
function onClearAlert() {
  return clearAlertSubject.asObservable();
}
function clearAlerts(alertsToClear){
  clearAlertSubject.next(alertsToClear);
}
// core alert method
function alert(alert) {
  alert.id = alert.id || defaultId;
  alert.autoClose = alert.autoClose === undefined ? true : alert.autoClose;
  alertSubject.next(alert);
}

// clear alerts
function clear(id = defaultId) {
  alertSubject.next({ id });
}

// convenience methods
function success(message, options) {
  alert({ ...options, type: AlertType.Success, message });
}

function error(message, options) {
  alert({ ...options, type: AlertType.Error, message });
}

function info(message, options) {
  alert({ ...options, type: AlertType.Info, message });
}

function warn(message, options) {
  alert({ ...options, type: AlertType.Warning, message });
}

function def(message, options) {
  alert({ ...options, type: AlertType.Default, message });
}

function httpError(message, options, errObj) {

  let errDetails = "";
  if (errObj.response) {
    // The request was made and the server responded with an error status code.
    if (errObj.response.status === 500) {
      // Handle the 500 Internal Server Error
      errDetails = `Server encountered an error. ${errObj.response.data.Message}`;
    } else {
      // Handle other HTTP error statuses if needed
      errDetails = `HTTP Error: ${errObj.response.status}`;
    }
  } else if (errObj.request) {
    // The request was made but no response was received.
    errDetails = 'No response received from the server.';
  } else {
    // Something happened in setting up the request that triggered an error.
    errDetails = 'Error occurred while sending the request.';
  }
  const msg = `${message} ${errDetails}`;
  error(msg, options);
  
}



