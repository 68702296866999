import React, { lazy } from 'react';
import Loadable from '../layouts/full-layout/loadable/Loadable';

/* ***Layouts**** */
const AppLayout = Loadable(lazy(() => import('../layouts/app-layout/AppLayout')));


/* ****Pages***** */
const MainDashboard = Loadable(lazy(() => import('../views/dashboards/MainDashboard')));


// Application Related
const LensCalculationForm = Loadable(lazy(() => import('../views/jobs/LensCalculationForm')));
const JobsList = Loadable(lazy(() => import('../views/jobs/JobsList')));
const JobSheet = Loadable(lazy(() => import('../views/jobs/JobSheet')));

const AddEditSingleTool = Loadable(lazy(() => import('../views/tools/AddEditSingleTool')));
const ToolsList = Loadable(lazy(() => import('../views/tools/ToolsList')));
const AddToolRange = Loadable(lazy(() => import('../views/tools/AddToolRange')));

const BlankColorList = Loadable(lazy(() => import('../views/blankColor/BlankColorList')));
const AddEditBlankColor = Loadable(lazy(() => import('../views/blankColor/AddEditBlankColor')));

const BlankManufacturersList = Loadable(lazy(() => import('../views/blankManufacturers/BlankManufacturersList')));
const AddEditBlankManufacturers = Loadable(lazy(() => import('../views/blankManufacturers/AddEditBlankManufacturers')));

const BlankTypesList = Loadable(lazy(() => import('../views/blankTypes/BlankTypesList')));
const AddEditBlankType = Loadable(lazy(() => import('../views/blankTypes/AddEditBlankType')));

const AddEditBlank = Loadable(lazy(() => import('../views/blanks/AddEditBlank')));
const BlanksList = Loadable(lazy(() => import('../views/blanks/BlanksList')));

const LensIndexList = Loadable(lazy(() => import('../views/lensIndexes/LensIndexList')));
const AddEditLensIndex = Loadable(lazy(() => import('../views/lensIndexes/AddEditLensIndex')));

const LensTypesList = Loadable(lazy(() => import('../views/lensTypes/LensTypesList')));
const AddEditLensTypes = Loadable(lazy(() => import('../views/lensTypes/AddEditLensTypes')));


const FrameTypesList = Loadable(lazy(() => import('../views/frameTypes/FrameTypesList')));
const AddEditFrameType = Loadable(lazy(() => import('../views/frameTypes/AddEditFrameType')));

const BlockTypesList = Loadable(lazy(() => import('../views/blockTypes/BlockTypesList')));
const AddEditBlockType = Loadable(lazy(() => import('../views/blockTypes/AddEditBlockType')));

const CoatingTypesList = Loadable(lazy(() => import('../views/coatingTypes/CoatingTypesList')));
const AddEditCoatingType = Loadable(lazy(() => import('../views/coatingTypes/AddEditCoatingType')));

const CustomersList = Loadable(lazy(() => import('../views/customers/CustomersList')));
const AddEditCustomer = Loadable(lazy(() => import('../views/customers/AddEditCustomer')));

const EditSettings = Loadable(lazy(() => import('../views/settings/AddEditSettings')));
const EditSetParameter = Loadable(lazy(() => import('../views/set-parameters/AddEditSetParameters')));

const PowerToBlankMapList = Loadable(lazy(() => import('../views/powerToBlankMap/PowerToBlankMapList')));
const AddEditPowerToBlankMap = Loadable(lazy(() => import('../views/powerToBlankMap/AddEditPowerToBlankMap')));

const PowerToEtCtMapList = Loadable(lazy(() => import('../views/powerToEtCtMap/PowerToEtCtMapList')));
const AddEditPowerToEtCtMap = Loadable(lazy(() => import('../views/powerToEtCtMap/AddEditPowerToEtCtMap')));


/* ****Routes***** */

const Router = [
    {
        path: '/',
        element: <AppLayout />,
        children: [
            // { path: '/', element: <Navigate to="/app/lenscalc" /> },
            { path: '/', element: <MainDashboard /> },
            // { path: '/app/lenscalc', exact: true, element: <LensCalculationForm /> },

            { path: '/jobs/list', exact: true, element: <JobsList /> },
            { path: '/jobs/add', exact: true, element: <LensCalculationForm />, key:"add"},
            { path: '/jobs/edit/:id', exact: true, element: <LensCalculationForm />, key:"edit" },
            { path: '/jobs/view/:id', exact: true, element: <JobSheet /> },
            

            { path: '/tools/add-single-tool', exact: true, element: <AddEditSingleTool /> },
            { path: '/tools/view-all-tools', exact: true, element: <ToolsList /> },
            { path: '/tools/edit-single-tool/:id', exact: true, element: <AddEditSingleTool /> },
            // { path: '/tools/remove-single-tool', exact: true, element: <AddEditSingleTool /> },
            { path: '/tools/add-tool-range', exact: true, element: <AddToolRange /> },
            // { path: '/tools/remove-tool-range', exact: true, element: <AddEditSingleTool /> },
            { path: '/tools/unavailable-tools-report', exact: true, element: <AddEditSingleTool /> },
            { path: '/tools/delete-unavailable-tools', exact: true, element: <AddEditSingleTool /> },


            { path: '/masters/blank-colors/list', exact: true, element: <BlankColorList /> },
            { path: '/masters/blank-colors/add', exact: true, element: <AddEditBlankColor /> },
            { path: '/masters/blank-colors/edit/:id', exact: true, element: <AddEditBlankColor /> },

            { path: '/masters/blank-manufacturers/list', exact: true, element: <BlankManufacturersList /> },
            { path: '/masters/blank-manufacturers/add', exact: true, element: <AddEditBlankManufacturers /> },
            { path: '/masters/blank-manufacturers/edit/:id', exact: true, element: <AddEditBlankManufacturers /> },

            { path: '/masters/blank-types/list', exact: true, element: <BlankTypesList /> },
            { path: '/masters/blank-types/add', exact: true, element: <AddEditBlankType /> },
            { path: '/masters/blank-types/edit/:id', exact: true, element: <AddEditBlankType /> },

            { path: '/masters/blanks/list', exact: true, element: <BlanksList /> },
            { path: '/masters/blanks/add', exact: true, element: <AddEditBlank /> },
            { path: '/masters/blanks/edit/:id', exact: true, element: <AddEditBlank /> },

            { path: '/masters/lens-indexes/list', exact: true, element: <LensIndexList /> },
            { path: '/masters/lens-indexes/add', exact: true, element: <AddEditLensIndex /> },
            { path: '/masters/lens-indexes/edit/:id', exact: true, element: <AddEditLensIndex /> },

            { path: '/masters/lens-types/list', exact: true, element: <LensTypesList /> },
            { path: '/masters/lens-types/add', exact: true, element: <AddEditLensTypes /> },
            { path: '/masters/lens-types/edit/:id', exact: true, element: <AddEditLensTypes /> },


            { path: '/masters/frame-types/list', exact: true, element: <FrameTypesList /> },
            { path: '/masters/frame-types/add', exact: true, element: <AddEditFrameType /> },
            { path: '/masters/frame-types/edit/:id', exact: true, element: <AddEditFrameType /> },

            { path: '/masters/block-types/list', exact: true, element: <BlockTypesList /> },
            { path: '/masters/block-types/add', exact: true, element: <AddEditBlockType /> },
            { path: '/masters/block-types/edit/:id', exact: true, element: <AddEditBlockType /> },

            { path: '/masters/coating-types/list', exact: true, element: <CoatingTypesList /> },
            { path: '/masters/coating-types/add', exact: true, element: <AddEditCoatingType /> },
            { path: '/masters/coating-types/edit/:id', exact: true, element: <AddEditCoatingType /> },

            { path: '/masters/customers/list', exact: true, element: <CustomersList /> },
            { path: '/masters/customers/add', exact: true, element: <AddEditCustomer /> },
            { path: '/masters/customers/edit/:id', exact: true, element: <AddEditCustomer /> },

            { path: '/masters/settings/edit/:id', exact: true, element: <EditSettings /> },
            { path: '/masters/set-parameters/edit/:id', exact: true, element: <EditSetParameter /> },

            { path: '/masters/power-to-blank-map/list', exact: true, element: <PowerToBlankMapList /> },
            { path: '/masters/power-to-blank-map/add', exact: true, element: <AddEditPowerToBlankMap /> },
            { path: '/masters/power-to-blank-map/edit/:id', exact: true, element: <AddEditPowerToBlankMap /> },
            
            { path: '/masters/power-to-et-ct-map/list', exact: true, element: <PowerToEtCtMapList /> },
            { path: '/masters/power-to-et-ct-map/add', exact: true, element: <AddEditPowerToEtCtMap /> },
            { path: '/masters/power-to-et-ct-map/edit/:id', exact: true, element: <AddEditPowerToEtCtMap /> },
            
            { path: '/sfb/sf-blank-information', exact: true, element: <AddEditSingleTool /> },
            { path: '/sfb/sfb-power', exact: true, element: <AddEditSingleTool /> },
            { path: '/sfb/sfb-power-chart', exact: true, element: <AddEditSingleTool /> },
            { path: '/sfb/export-sf-blank-information', exact: true, element: <AddEditSingleTool /> },

            { path: '/utilities/reset-job-id', exact: true, element: <AddEditSingleTool /> },
            { path: '/utilities/restore-factory-settings', exact: true, element: <AddEditSingleTool /> },
            { path: '/utilities/database-backup', exact: true, element: <AddEditSingleTool /> },
            { path: '/utilities/database-restore', exact: true, element: <AddEditSingleTool /> },
            { path: '/utilities/delete-lens-types', exact: true, element: <AddEditSingleTool /> },
            { path: '/utilities/user-account-management', exact: true, element: <AddEditSingleTool /> },
            { path: '/utilities/remove-records', exact: true, element: <AddEditSingleTool /> },
            { path: '/utilities/enable-auto-print-job-sheet', exact: true, element: <AddEditSingleTool /> },

            { path: '/settings/frame-information', exact: true, element: <AddEditSingleTool /> },
            { path: '/settings/export-settings', exact: true, element: <AddEditSingleTool /> },

            { path: '/reports/print-job-sheet', exact: true, element: <AddEditSingleTool /> },
            { path: '/reports/print-job-label', exact: true, element: <AddEditSingleTool /> },
            { path: '/reports/print-job-label-type-2', exact: true, element: <AddEditSingleTool /> },
            { path: '/reports/print-sfb-report', exact: true, element: <AddEditSingleTool /> },
            { path: '/reports/print-multiple-job-sheets', exact: true, element: <AddEditSingleTool /> },
            { path: '/reports/print-multiple-job-label', exact: true, element: <AddEditSingleTool /> },
            { path: '/reports/print-multiple-job-label-type-2', exact: true, element: <AddEditSingleTool /> },

            { path: '/settings/tool-pcd', exact: true, element: <AddEditSingleTool /> },
            { path: '/settings/lip-radius', exact: true, element: <AddEditSingleTool /> },
            { path: '/settings/sag-gauge-dia', exact: true, element: <AddEditSingleTool /> },
            { path: '/settings/sag-gauge-ball-dia', exact: true, element: <AddEditSingleTool /> },
            { path: '/settings/tool-index', exact: true, element: <AddEditSingleTool /> },
            { path: '/settings/tool-setup', exact: true, element: <AddEditSingleTool /> },
            { path: '/settings/axis-turn', exact: true, element: <AddEditSingleTool /> },
            { path: '/settings/power-dia', exact: true, element: <AddEditSingleTool /> },
            { path: '/settings/frame-information', exact: true, element: <AddEditSingleTool /> },
            { path: '/settings/export-settings', exact: true, element: <AddEditSingleTool /> },
            { path: '/settings/import-settings', exact: true, element: <AddEditSingleTool /> },
        ],
    },
];

export default Router;
