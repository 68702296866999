import React from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { SnackbarUtilsConfigurator } from './utils/SnackBarUtils';
import { Alert } from "./components/alert/alert";
import { StatusMessage } from "./components/alert/StatusMessage";
import './styles/app.scss';

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  //const customizer = useSelector((state) => state.CustomizerReducer);
  return (
    <ThemeProvider theme={theme}>
      <StatusMessage />
      <SnackbarUtilsConfigurator />
      <Alert />
      
        <CssBaseline />
        {routing}
      
    </ThemeProvider>
  );
};

export default App;
