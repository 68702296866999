import React, { useState, useEffect } from 'react';
import { AlertType } from '../../services/alertService';
import { statusMessageService } from '../../services/statusMessageService';

function StatusMessage() {

    const [message, setMessage] = useState(null);
    
    const messageClasses = {
        [AlertType.Success]: "top-message-success",
        [AlertType.Error]: "top-message-error",
        [AlertType.Info]: "top-message-info",
        [AlertType.Warning]: "top-message-warning",
        [AlertType.Default]: "top-message-default",
    }
    useEffect(() => {
        // subscribe to new alert notifications
        const subscription = statusMessageService.onMessage().subscribe((newMessage) => {
            setMessage(newMessage);
        });
        // clean up function that runs when the component unmounts
        return () => {
            subscription.unsubscribe();
        };
    }, [message]);

    if (message && message.message){
        let clsName = messageClasses[message.type] ?? "top-message-default";

        return (
            <div className="top-message-outer">
                <span className={"top-message "+clsName}>{message.message}</span>
            </div>
        );
        //return <span className={"top-message "+clsName}>{message.message}</span>
    }
    else null;
}


export { StatusMessage };