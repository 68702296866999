import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alertService } from '../../services';
import { SnackBarMessage } from '../snack-bar/SnackBarMessage';

const propTypes = {
    id: PropTypes.string,
};

const defaultProps = {
    id: 'default-alert',
};
// Ned to cleanup after testing
const Alert = ({ id }) => {
    const [alerts, setAlerts] = useState([]);
    const [alert, setAlert] = useState(null);
    const shownAlertRef = React.useRef([]);
    
    useEffect(() => {
        // subscribe to new alert notifications
        const subscription = alertService.onAlert(id).subscribe((newAlert) => {
            // add alert to array
            // console.log('alert added useEffect',newAlert);
            // setAlerts((alerts) => [...alerts, alert]);
            setAlerts((alrts) => [...(alrts.filter((x) => shownAlertRef.current.indexOf(x) < 0)),newAlert]);
            // console.log('alerts after adding ',alerts);
            shownAlertRef.current = [];
            // setAlerts((alerts) => [alert]);
            // setAlert(newAlert);
        });
        const clearSubscription = alertService.onClearAlert().subscribe((alertsToClear) => {
            // console.log('Clearing ',alertsToClear);
            shownAlertRef.current = alertsToClear;
            
        });

        // clean up function that runs when the component unmounts
        return () => {
            // unsubscribe & unlisten to avoid memory leaks
            subscription.unsubscribe();
            clearSubscription.unsubscribe();
        };
    }, [alerts]);
   
    
    /*
    if (!alert) return null;
    return <SnackBarMessage messageDetails={{ message: alert.message, severity: alert.type }} />
    */
    /*
    function removeAlert(alert) {
        setAlerts((alerts) => alerts.filter((x) => x !== alert));
    }
    function removeAlerts(alertToRemove) {
      setAlerts((alerts) => alerts.filter((x) => alertToRemove.indexOf(x) < 0));
    }
    */
    // console.log('Remaining Alerts A ',alerts);
    //
    const filteredAlerts = alerts.filter((x) => shownAlertRef.current.indexOf(x) < 0);
    if (!filteredAlerts.length) return null;
    alertService.clearAlerts(filteredAlerts);
    return (
        <>
            {filteredAlerts.map((al, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <SnackBarMessage autoHideDuration={1000} key={index} messageDetails={{ message: al.message, severity: al.type }} />
            ))}
        </>
    );
    /*
    if (!alerts.length) return null;
    
    let alertsToShow = [...alerts];
    
  
    console.log('alertsToShow ',alertsToShow);
    console.log('Remaining Alerts B ',alerts);
    alertsToShow.map((alert, index) => console.log(index,alert.message,alert.type));
    alertService.clearAlerts(alertsToShow);
    return (
        <>
            {alerts.map((alert, index) => (
                <SnackBarMessage key={index} messageDetails={{ message: alert.message, severity: alert.type }} />
            ))}
        </>
    );
    */
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;
export { Alert };
