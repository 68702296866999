// Layout and colors CONSTANTS
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const RED_THEME = 'RED_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';
export const ORANGE_THEME = 'ORANGE_THEME';
// CHAT CONSTANTS
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const SELECTED_CHAT = 'SELECTED_CHAT';
export const SEARCH_USER = 'SEARCH_USER';
export const MSG_SUBMIT = 'MSG_SUBMIT';

// NOTES CONSTANTS
export const SELECTED_NOTES = 'SELECTED_NOTES';
export const SEARCH_NOTES = 'SEARCH_NOTES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const ADD_NOTE = 'ADD_NOTE';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';

// EMAIL CONSTANTS
export const STAR_EMAIL = 'STAR_EMAIL';
export const IMPORTANT_EMAIL = 'IMPORTANT_EMAIL';
export const OPEN_EMAIL = 'OPEN_EMAIL';
export const TRASH_EMAIL = 'TRASH_EMAIL';
export const ASSIGN_FOLDER = 'ASSIGN_FOLDER';
export const ASSIGN_LABEL = 'ASSIGN_LABEL';
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL';
export const FILTER_EMAIL = 'FILTER_EMAIL';
export const SET_EMAIL_VISIBILITY_FILTER = 'SET_EMAIL_VISIBILITY_FILTER';


// For Calculation
export const CALC_GET_DEFAULT_VALUES = 'CALC_GET_DEFAULT_VALUES';
export const CALC_CALCULATE = 'CALC_CALCULATE';
export const CALC_FETCH_VALUES_SUCCESS = 'CALC_FETCH_VALUES_SUCCESS';
//export const CALC_FETCH_VALUES_ERROR = 'CALC_FETCH_VALUES_ERROR';

//API

export const API_SUCCESS = 'API_SUCCESS';
export const API_ERROR = 'API_ERROR';


export const API_NAME_BLANK_COLOR = 'BLANK_COLOR';
export const API_NAME_BLANK_MANUFACTURER = '_BLANK_MANUFACTURER';
export const API_NAME_BLANK_TYPE = 'BLANK_TYPE';
export const API_NAME_LENS_INDEX = 'LENS_INDEX';
export const API_NAME_LENS_TYPE = 'LENS_TYPE';
export const API_NAME_BLANK = 'BLANK';

export const API_CALL_METHOD_LIST = 'list';
export const API_CALL_METHOD_GET = 'get';
export const API_CALL_METHOD_ADD = 'add';
export const API_CALL_METHOD_PUT = 'put';
export const API_CALL_METHOD_DELETE = 'delete';

