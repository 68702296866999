import { Subject } from "rxjs";
import { AlertType } from './alertService';
const messageSubject = new Subject();

export const statusMessageService = {
  onMessage,
  success,
  error,
  info,
  warn,
  alert,
  def,
  clear
};

// enable subscribing to alerts observable
function onMessage() {
  return messageSubject.asObservable().pipe(x => x);
}

// core alert method
function postMessage(message) {
  messageSubject.next(message);

  if (message && message.timeout){
    const myTimeout = setTimeout(() => {
      messageSubject.next(null);
      clearTimeout(myTimeout);
    }, message.timeout);
  }
}

// convenience methods
function success(message, options) {
  postMessage({ ...options, type: AlertType.Success, message });
}

function error(message, options) {
  postMessage({ ...options, type: AlertType.Error, message });
}

function info(message, options) {
  postMessage({ ...options, type: AlertType.Info, message });
}

function warn(message, options) {
  postMessage({ ...options, type: AlertType.Warning, message });
}

function def(message, options) {
  postMessage({ ...options, type: AlertType.Default, message });
}
function clear() {
  postMessage(null);
}



